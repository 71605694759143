<template>
  <div>
      <el-container>
          <el-aside width="auto">
              <common-aside></common-aside>
          </el-aside>
          <el-container>
              <el-header>
                  <common-header></common-header>
              </el-header>
              <common-tag style="border-left: 1px solid #efefef;"></common-tag>
              <el-main style="border-left: 1px solid #efefef;padding-top: 0;">
                  <router-view></router-view>
              </el-main>
          </el-container>
      </el-container>
  </div>
</template>

<script>

import CommonAside from '../components/CommonAside.vue'
import CommonHeader from '../components/CommonHeader.vue'
import CommonTag from '../components/CommonTag.vue'
import Cookie from "js-cookie"

export default {
    components: {
        CommonAside,
        CommonHeader,
        CommonTag
    },
    created() {
        this.keepStatus()
    },
    methods: {
        keepStatus() {
            const menus = JSON.parse(Cookie.get('menus'))
            let currentMenu

            menus.forEach((menu) => {
                if (menu.children.length) {
                    menu.children.forEach((item) => {
                        if (item.name === this.$route.name) {
                            currentMenu = item
                        }
                    })
                } else {
                    if (menu.name === this.$route.name) {
                        currentMenu = menu
                    }
                }
            })

            if (currentMenu !== undefined) {
                // 刷新确保面包屑导航和tag保持和菜单一致
                this.$store.commit('setBread', currentMenu)
                this.$store.commit('setTag', currentMenu)
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .el-header {
        padding: 0;
    }
</style>
