<template>
    <div class="password">
        <h3>修改密码</h3>
        <el-form ref="form" :model="form" :rules="rules" style="width: 600px;">
            <el-form-item label="旧密码" prop="old_password">
                <el-input id="old_password" show-password v-model="form.old_password" placeholder="请输入旧密码"></el-input>
            </el-form-item>

            <el-form-item label="新密码" prop="password">
                <el-input id="password" show-password v-model="form.password" placeholder="请输入新密码"></el-input>
            </el-form-item>

            <el-form-item label="确认密码" prop="password_confirmation">
                <el-input id="password_confirmation" show-password v-model="form.password_confirmation" placeholder="请输入确认密码"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" size="small" @click="doUpdate">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>

import { updatePassword } from "@/api/user"
import Cookie from "js-cookie"

export default {
    data() {
        const validatePassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入新密码'))
            } else {
                if (this.form.password_confirmation !== '') {
                    this.$refs.form.validateField('password_confirmation')
                }
                callback()
            }
        }

        const validateConfirmPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入确认密码'))
            } else if (value !== this.form.password) {
                callback(new Error('两次输入密码不一致!'))
            } else {
                callback()
            }
        }

        return {
            form: {
                old_password: '',
                password: '',
                password_confirmation: '',
            },
            rules: {
                old_password: [
                    {required: true, message: '请输入旧密码', trigger: 'blur'},
                    {min: 6, max: 32, message: '密码长度在 6 到 32 个字符之间', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '请输入新密码', trigger: 'blur'},
                    {min: 6, max: 32, message: '密码长度在 6 到 32 个字符之间', trigger: 'blur'},
                    {validator: validatePassword, trigger: 'blur'}
                ],
                password_confirmation: [
                    {required: true, message: '请输入确认密码', trigger: 'blur'},
                    {min: 6, max: 32, message: '密码长度在 6 到 32 个字符之间', trigger: 'blur'},
                    {validator: validateConfirmPassword, trigger: 'blur'}
                ]
            }
        }
    },
    created() {

    },
    methods: {
        doUpdate() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    updatePassword(this.form).then(({ data }) => {
                        if (data.code === 200) {
                            this.$message({
                                type: 'success',
                                message: '密码修改成功，请重新登录',
                                duration: 3000,
                                onClose: () => {
                                    Cookie.remove('token')
                                    Cookie.remove('menus')
                                    this.$router.push({name: 'login'})
                                }
                            })
                        } else {
                            this.$message.error(data.data)
                        }
                    })
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
    .password {
        h3 {
            font-size: 16px;
            font-weight: normal;
            padding: 12px 0;
            color: #333;
        }

        .el-form-item {
            /deep/.el-form-item__label {
                float: none;
                padding-left: 2px;
            }
        }
    }
</style>