<template>
  <div class="login">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <h3 class="login-title">欢迎登录</h3>

          <el-form-item label="用户名" prop="name">
              <el-input id="name" size="small" v-model="form.name" placeholder="请输入用户名"></el-input>
          </el-form-item>

          <el-form-item label="密码" prop="password">
              <el-input type="password" id="password" size="small" v-model="form.password" @keyup.enter.native="doLogin" placeholder="请输入密码"></el-input>
          </el-form-item>

          <el-form-item>
              <el-button type="primary" size="small" @click="doLogin">登 录</el-button>
              <el-link type="primary" :underline="false" @click="register">快速注册</el-link>
          </el-form-item>
      </el-form>
  </div>
</template>

<script>

import Cookie from "js-cookie"
import { login } from "@/api"

export default {
    data() {
        return {
            form: {
                name: '',
                password: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        doLogin() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    login(this.form).then(({ data }) => {

                        if (data.code === 201) {
                            Cookie.set('token', data.data.token)
                            this.$store.commit('setMenus', data.data.menus)
                            this.$store.commit('setAvatar', data.data.user.avatar)
                            this.$store.commit('setUser', data.data.user)
                            this.$store.commit('setRoutes', this.$router)
                            this.$store.commit('setConfig', data.data.config)

                            this.$message({
                                type: 'success',
                                message: '登录成功',
                                duration: 1000,
                                onClose: () => {
                                    this.$router.push({name: 'home'})
                                }
                            })
                        } else {
                            this.$message.error(data.data)
                        }
                    })
                }
            })
        },
        register() {
            this.$router.push({name: 'register'})
        }
    }
}
</script>

<style lang="less" scoped>
.login {
    width: 350px;
    border: 1px solid #eaeaea;
    margin: 180px auto;
    background: #ffffff;
    padding: 35px 35px 15px 35px;
    border-radius: 15px;
    box-shadow: 0 0 25px #cac6c6;

    .login-title {
        text-align: center;
        margin-bottom: 25px;
        color: #505458;
        font-weight: normal;
        font-size: 18px;
    }

    /deep/.el-link--inner {
        font-weight: normal;
        font-size: 13px;
        margin-left: 20px;
    }
}
</style>
