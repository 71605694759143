import Mock from 'mockjs'
import homeApi from './mockData/home'
// import user from './mockData/user'
// import permission from './mockData/permission'

Mock.mock(/api\/v1\/home\/statistics/, homeApi.getStatistics)

// 用户api
//Mock.mock('/api/v1/user/add', user.createUser)
//Mock.mock('/api/v1/user/edit', user.updateUser)
//Mock.mock('/api/v1/user/delete', user.deleteUser)
//Mock.mock(/api\/v1\/user\/getList/, user.getUserList)

// 登录api
// Mock.mock('/api/login', permission.login)
