import http from '../utils/request'

export const getProfile = () => {
    return http.get('/users/info')
}

export const getInit = () => {
    return http.get('/users/params')
}

export const updateProfile = (data) => {
    return http.post('/users/profile', data)
}

export const updatePassword = (data) => {
    return http.put('/password', data)
}

export const getUserList = (params) => {
    return http.get('/users', params)
}

export const addUser = (data) => {
    return http.post('/users', data)
}

export const editUser = (user, data) => {
    return http.put(`/users/${user}`, data)
}

export const deleteUser = (user) => {
    return http.delete(`/users/${user}`)
}

export const statusUser = (user) => {
    return http.patch(`/users/${user}/status`)
}
