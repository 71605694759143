import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '../views/Main.vue'
import Login from '../views/Login.vue'
import Cookie from 'js-cookie'
import { Message } from 'element-ui'
import Register from "@/views/Register.vue"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/home',
        name: 'main',
        component: Main,
        children: []
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    }
]

const router = new VueRouter({
    routes
})

// console.log('路由注册')

// 导航守卫
router.beforeEach((to, from, next) => {
    const token = Cookie.get('token')
    const routes = ['login', 'register']

    if (!token && !routes.includes(to.name)) {
        Message({
            message: '请先登录',
            type: 'error'
        })
        next({name: 'login'}) // 相当于laravel auth 中间件的逻辑
    } else if (token && routes.includes(to.name)) {
        Message({
            message: '你已经登录，请先退出登录',
            type: 'error'
        })
        next({name: 'home'})  // 相当于laravel guest中间件的逻辑
    } else {
        next()
    }
})

export default router
