<template>
    <div class="profile">
        <h3>我的信息</h3>
        <el-form ref="form" :model="form" :rules="rules" style="width: 600px;">
            <el-form-item label="头像" class="avatar">
                <img class="user" :src="form.avatar" alt="">
                <el-upload
                    class="upload-demo"
                    :action="`${apiUrl}/users/avatar`"
                    name="avatar"
                    :headers="headers"
                    :show-file-list="false"
                    :on-error="handleResult"
                    :on-success="handleResult">
                    <el-button size="small">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1M</div>
                </el-upload>
            </el-form-item>

            <el-form-item label="用户名" prop="name">
                <el-input id="name" v-model="form.name" placeholder="请输入用户名"></el-input>
            </el-form-item>

            <el-form-item label="邮箱" prop="email">
                <el-input id="email" v-model="form.email" placeholder="请输入邮箱"></el-input>
            </el-form-item>

            <el-form-item label="昵称" prop="nickname">
                <el-input id="nickname" v-model="form.nickname" placeholder="请输入昵称"></el-input>
            </el-form-item>

            <el-form-item label="性别" prop="gender">
                <el-select id="gender" v-model="form.gender" placeholder="请选择">
                    <el-option v-for="(item, key) in genderMap" :key="key" :label="item" :value="parseInt(key)"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="简介" prop="description">
                <el-input type="textarea" id="description" :rows="2" v-model="form.description" placeholder="请输入简介"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" size="small" @click="doUpdate">更新信息</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>

import { getProfile, updateProfile } from "@/api/user"
import Cookie from "js-cookie"

export default {
    data() {
        return {
            form: {
                avatar: '',
                name: '',
                email: '',
                nickname: '',
                gender: '',
                description: ''
            },
            genderMap: {
                "1": "男",
                "2": "女",
                "3": "未设置",
            },
            rules: {
                name: [
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                nickname: [
                    { required: true, message: '请输入昵称', trigger: 'blur' }
                ]
            },
            headers: {
                Authorization: Cookie.get('token'),
                Accept: 'application/json'
            },
            apiUrl: window.g.API_URL
        }
    },
    created() {
        this.getProfile()
    },
    methods: {
        getProfile() {
            getProfile().then(({ data }) => {
                if (data.code === 200) {
                    this.form = data.data
                } else {
                    this.$message.error(data.data)
                }
            })
        },
        doUpdate() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    updateProfile(this.form).then(({ data }) => {
                        if (data.code === 200) {
                            this.form = data.data
                            this.$message.success('信息更新成功')
                        } else {
                            this.$message.error(data.data)
                        }
                    })
                }
            })
        },
        handleResult(data) {
            if (data.code === 200) {
                this.form = data.data
                this.$store.commit('setAvatar', data.data.avatar)
            } else {
                this.$message.error(JSON.parse(data.message).data)
            }
        }
    }
}
</script>

<style scoped lang="less">
    .profile {
        h3 {
            font-size: 16px;
            font-weight: normal;
            padding: 12px 0;
            color: #333;
        }

        .el-form-item.avatar {
            /deep/.el-form-item__content {
                display: flex;
                align-items: center;
                justify-content: left;
                margin-top: 5px;

                .upload-demo {
                    margin-left: 20px;
                    .el-upload__tip {
                        margin-top: 0;
                        line-height: 28px;
                    }
                }
            }
        }

        .el-form-item {
            .user {
                width: 70px;
                height: 70px;
                border-radius: 50%;
            }

            /deep/.el-form-item__label {
                float: none;
                padding-left: 2px;
            }
        }
    }
</style>