<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  created() {
      this.$store.commit('setRoutes', this.$router)
  }
}
</script>

<style lang="less">
  html,body,h3,p {
    margin: 0;
    padding: 0;
  }

  [v-cloak]{
    display: none !important;
  }
</style>
