import Cookie from "js-cookie"
import Profile from "@/views/Profile.vue"
import photoAdd from "@/views/PhotoAdd.vue"
import Password from "@/views/Password.vue"

const home = {
    path: '/',
    name: 'home',
    label: '首页',
    icon: 's-data',
    url: 'Home/Home'
}

export default {
    state: {
        isCollapse: false,
        breads: [
            home
        ],
        tags: [
            home
        ],
        menus: [home],
        avatar: '',
        user: {},
        config: {}
    },
    mutations: {
        collapseMenu(state) {
            state.isCollapse = !state.isCollapse
        },
        setBread(state, val) {
            if (val.name !== 'home') {
                state.breads = [state.breads[0], val]
            } else {
                state.breads = [state.breads[0]]
            }
        },
        setTag(state, val) {
            if (val.name !== 'home') {
                const index = state.tags.findIndex(item => item.name === val.name)
                index === -1 && state.tags.push(val)
            }
        },
        clearTagsAndBreads(state) {
            state.tags = [home]
            state.breads = [home]
        },
        closeTag(state, item) {
            const index = state.tags.findIndex(val => val.name === item.name)
            state.tags.splice(index, 1)
        },
        setMenus(state, val) {
            state.menus = val
            Cookie.set('menus', JSON.stringify(val))
        },
        setAvatar(state, val) {
            state.avatar = val
            Cookie.set('avatar', val)
        },
        setUser(state, val) {
            state.user = val
            Cookie.set('user', JSON.stringify(val))
        },
        setConfig(state, val) {
            state.config = val
            Cookie.set('config', JSON.stringify(val))
        },
        setRoutes(state, router) {
            if (!Cookie.get('menus')) {
                return false
            }
            const menus = JSON.parse(Cookie.get('menus'))
            state.menus = menus
            const routes = []

            menus.forEach((menu) => {
                if (menu.children.length) {
                    menu.children.forEach((menu) => {
                        menu.component = () => import(`../views/${menu.url}.vue`)
                        routes.push(menu)
                    })
                } else {
                    menu.component = () => import(`../views/${menu.url}.vue`)
                    routes.push(menu)
                }
            })

            routes.forEach((route) => {
                router.addRoute('main', route) // 动态加载路由
            })

            router.addRoute('main', {path: '/profile', name: 'profile', component: Profile})
            router.addRoute('main', {path: '/password', name: 'password', component: Password})
            router.addRoute('main', {path: '/photos-add', name: 'photos.add', component: photoAdd})
        }
    }
}
