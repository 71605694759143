import http from '../utils/request'
export const getPlanList = (params) => {
    return http.get('/plans', params)
}

export const getInit = () => {
    return http.get('/plans/params')
}

export const addPlan = (data) => {
    return http.post('/plans', data)
}

export const getPlanDetail = (plan, params) => {
    return http.get(`/plans/${plan}`, params)
}

export const editPlan = (plan, data) => {
    return http.put(`/plans/${plan}`, data)
}

export const deletePlan = (plan) => {
    return http.delete(`/plans/${plan}`)
}

export const deletePhoto = (plan, photo) => {
    return http.delete(`/plans/${plan}/photos/${photo}`)
}

export const commentPhoto = (plan, photo, data) => {
    return http.patch(`/plans/${plan}/photos/${photo}/comments`, data)
}

export const deleteRetouch = (plan, photo) => {
    return http.patch(`/plans/${plan}/photos/${photo}/retouch`)
}

export const togglePhoto = (plan, photo) => {
    return http.patch(`/plans/${plan}/photos/${photo}/toggle`)
}
