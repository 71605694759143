<template>
  <div class="tags">
      <el-tag v-for="(item, index) in tags" :key="item.path"
              :closable="item.name !== 'home'"
              size="small"
              :effect="$route.name === item.name ? 'dark' : 'plain'"
              @click="changeMenu(item)"
              @close="closeMenu(item, index)">
          {{ item.label }}
      </el-tag>
  </div>
</template>

<script>
  import { mapState, mapMutations } from "vuex"

  export default {
      computed: {
          ...mapState({
              tags: state => state.menu.tags
          })
      },
      methods: {
          ...mapMutations(['closeTag', 'setBread']),

          changeMenu(item) {
              if (this.$route.name !== item.name) {
                  this.$router.push({name: item.name})
                  this.setBread(item)
              }
          },
          closeMenu(item, index) {
              this.closeTag(item) //删除tag
              let route = null    //下一个跳转到的路由
              const length = this.tags.length

              if (item.name === this.$route.name) {
                  route = index === length ? this.tags[index-1] : this.tags[index]
                  this.$router.push({name: route.name})
              }
          }
      }
  }
</script>

<style lang="less" scoped>
  .tags {
    padding: 15px;

    .el-tag {
      &:first-child {
        margin-left: 6px;
      }
      margin-right: 10px;
      cursor: pointer;
    }
  }
</style>
