<template>
  <div>
      <el-menu
          :default-active="$route.name"
          class="el-menu-vertical-demo"
          :collapse="isCollapse"
          :collapse-transition="false"
          background-color="#ffffff"
          text-color="#606266"
          active-text-color="#409EFF">
          <h3>
              <span v-show="!isCollapse" v-cloak>{{ config['site.name'] }}</span>
              <span v-show="isCollapse" v-cloak>选片</span>
<!--              <img v-else src="../assets/logo.png" v-cloak alt="">-->
          </h3>
          <el-menu-item @click="clickMenu(item)" v-for="item in topMenus" :key="item.name" :index="item.name">
              <i :class="'el-icon-' + item.icon"></i>
              <span slot="title">{{ item.label }}</span>
          </el-menu-item>
          <el-submenu v-for="item in secondMenus" :key="item.name" :index="item.name">
              <template slot="title">
                  <i :class="'el-icon-' + item.icon"></i>
                  <span slot="title">{{ item.label }}</span>
              </template>
              <el-menu-item-group>
                  <el-menu-item @click="clickMenu(subItem)" v-for="subItem in item.children" :key="subItem.name" :index="subItem.name">{{ subItem.label }}</el-menu-item>
              </el-menu-item-group>
          </el-submenu>
      </el-menu>
  </div>
</template>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 220px;
    min-height: 400px;
}

.el-menu {
    height: 100vh;
    border-right: none;

    h3 {
        color: #fff;
        text-align: center;
        line-height: 60px;
        height: 60px;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #409EFF;
    }

    img {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
}
</style>

<script>

import Cookie from "js-cookie"

export default {
    data() {
        return {
            // menus: [
            //     {
            //         path: '/',
            //         name: 'home',
            //         label: '首页',
            //         icon: 's-data',
            //         url: 'Home/Home'
            //     },
            //     {
            //         path: '/goods',
            //         name: 'goods',
            //         label: '商品管理',
            //         icon: 'goods',
            //         url: 'Goods/Goods'
            //     },
            //     {
            //         path: '/users',
            //         name: 'users',
            //         label: '用户管理',
            //         icon: 'user',
            //         url: 'Users/Users'
            //     },
            //     {
            //         name: 'others',
            //         label: '其它设置',
            //         icon: 'setting',
            //         children: [
            //             {
            //                 path: '/page1',
            //                 name: 'page1',
            //                 label: '页面1',
            //                 icon: 'setting',
            //                 url: 'Others/PageOne'
            //             },
            //             {
            //                 path: '/page2',
            //                 name: 'page2',
            //                 label: '页面2',
            //                 icon: 'setting',
            //                 url: 'Others/PageTwo'
            //             }
            //         ]
            //     }
            // ]
        }
    },
    methods: {
        clickMenu(item) {
            if(this.$route.path !== item.path && !(this.$route.path === '/home' && item.path === '/')) {
                this.$router.push(item.path)
            }
            this.$store.commit('setTag', item)   // 设置tag
            this.$store.commit('setBread', item) // 设置面包屑导航
        }
    },
    computed: {
        menus() {
            return this.$store.state.menu.menus.length > 1 ? this.$store.state.menu.menus : JSON.parse(Cookie.get('menus'))
        },
        config() {
            return this.$store.state.menu.config['site.name'] !== undefined ? this.$store.state.menu.config : JSON.parse(Cookie.get('config'))
        },
        topMenus() {
            return this.menus.filter(item => item.children.length === 0)
        },
        secondMenus() {
            return this.menus.filter(item => item.children.length > 0)
        },
        isCollapse() {
            return this.$store.state.menu.isCollapse
        }
    }
}
</script>
