import http from '../utils/request'

export const getData = () => {
    return http.get('/home/statistics')
}

export const login = (data) => {
    return http.post('/login', data)
}

export const register = (data) => {
    return http.post('/register', data)
}