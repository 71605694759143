<template>
  <div class="header-container">
      <div class="l-content">
          <el-button style="margin-right: 15px;color: #409EFF;" @click="handleMenu" icon="el-icon-menu" size="mini"></el-button>
          <!-- 面包屑导航 -->
          <el-breadcrumb separator="/">
              <el-breadcrumb-item v-for="item in breads" :key="item.path">{{ item.label }}</el-breadcrumb-item>
          </el-breadcrumb>
      </div>
      <div class="r-content">
          <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                <img class="user" :src="avatar" alt="">
              </span>
              <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="profile">个人中心</el-dropdown-item>
                  <el-dropdown-item command="password">修改密码</el-dropdown-item>
                  <el-dropdown-item command="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
          </el-dropdown>
      </div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import Cookie from "js-cookie"

export default {
    data() {
        return {

        }
    },
    methods: {
        handleMenu() {
            this.$store.commit('collapseMenu')
        },
        handleCommand(command) {
            if (command === 'logout') {
                Cookie.remove('token')
                Cookie.remove('menus')
                Cookie.remove('avatar')
                Cookie.remove('user')
                this.$store.commit('clearTagsAndBreads') // 清除tag和面包屑导航
                this.$router.push({name: 'login'})
            } else if (command === 'profile' || command === 'password') {
                if (this.$route.name !== command) {
                    this.$router.push({name: command})
                }
            }
        }
    },
    computed: {
        ...mapState({
            breads: state => state.menu.breads,
            avatar: state => state.menu.avatar.length ? state.menu.avatar : Cookie.get('avatar')
        })
    }
}
</script>

<style lang="less" scoped>
.header-container {
    padding: 0 20px;
    background-color: #409EFF;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
        color: #fff;
        font-size: 14px;
        margin-left: 10px;
    }

    .l-content {
        display: flex;
        align-items: center;

        .el-button--mini {
            padding: 5px 13px;
        }

        /deep/.el-breadcrumb__item {
            .el-breadcrumb__separator {
                color: #ffffff;
            }
            .el-breadcrumb__inner {
                font-weight: normal;
                color: #ffffff;
            }
            &:last-child {
                .el-breadcrumb__inner {
                    color: #ffffff;
                }
            }
        }
    }

    .r-content {
        .user {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }
}
</style>
