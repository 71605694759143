<template>
  <div class="login">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <h3 class="login-title">欢迎注册</h3>

          <el-form-item label="用户名" prop="name">
              <el-input id="name" size="small" v-model="form.name" placeholder="请输入用户名"></el-input>
          </el-form-item>

          <el-form-item label="密码" prop="password">
              <el-input type="password" id="password" size="small" v-model="form.password" placeholder="请输入密码"></el-input>
          </el-form-item>

          <el-form-item label="确认密码" prop="password_confirmation">
              <el-input type="password" id="password_confirmation" size="small" v-model="form.password_confirmation" @keyup.enter.native="doRegister" placeholder="请输入确认密码"></el-input>
          </el-form-item>

          <el-form-item label="昵称" prop="nickname">
              <el-input id="nickname" size="small" v-model="form.nickname" placeholder="请输入昵称"></el-input>
          </el-form-item>

          <el-form-item label="身份" prop="role">
              <el-select v-model="form.role" size="small" style="width: 200px" placeholder="请选择身份">
                  <el-option label="摄影" :value="2"></el-option>
                  <el-option label="模特" :value="3"></el-option>
              </el-select>
          </el-form-item>

          <el-form-item>
              <el-button type="primary" size="small" @click="doRegister">注 册</el-button>
              <el-link type="primary" :underline="false" @click="login">去登录</el-link>
          </el-form-item>
      </el-form>
  </div>
</template>

<script>

import { register } from "@/api"

export default {
    data() {
        const validatePassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'))
            } else {
                if (this.form.password_confirmation !== '') {
                    this.$refs.form.validateField('password_confirmation')
                }
                callback()
            }
        }

        const validateConfirmPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入确认密码'))
            } else if (value !== this.form.password) {
                callback(new Error('两次输入密码不一致!'))
            } else {
                callback()
            }
        }

        return {
            form: {
                name: '',
                nickname: '',
                password: '',
                password_confirmation: '',
                role: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 2, max: 30, message: '用户名长度在 2 到 30 个字符之间', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 32, message: '密码长度在 6 到 32 个字符之间', trigger: 'blur' },
                    { validator: validatePassword, trigger: 'blur' }
                ],
                password_confirmation: [
                    { required: true, message: '请输入确认密码', trigger: 'blur' },
                    { min: 6, max: 32, message: '密码长度在 6 到 32 个字符之间', trigger: 'blur'},
                    { validator: validateConfirmPassword, trigger: 'blur' }
                ],
                nickname: [
                    { min: 2, max: 30, message: '昵称长度在 2 到 30 个字符之间', trigger: 'blur' }
                ],
                role: [
                    { required: true, message: '请选择身份', trigger: 'change' }
                ]
            }
        }
    },
    methods: {
        doRegister() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    register(this.form).then(({ data }) => {
                        if (data.code === 201) {
                            this.$message({
                                type: 'success',
                                message: '注册成功',
                                duration: 1000,
                                onClose: () => {
                                    this.$router.push({name: 'login'})
                                }
                            })
                        } else {
                            this.$message.error(data.data)
                        }
                    })
                }
            })
        },
        login() {
            this.$router.push({name: 'login'})
        }
    }
}
</script>

<style lang="less" scoped>
.login {
    width: 380px;
    border: 1px solid #eaeaea;
    margin: 120px auto;
    background: #ffffff;
    padding: 35px 35px 15px 35px;
    border-radius: 15px;
    box-shadow: 0 0 25px #cac6c6;

    .login-title {
        text-align: center;
        margin-bottom: 25px;
        color: #505458;
        font-weight: normal;
        font-size: 18px;
    }

    /deep/.el-link--inner {
        font-weight: normal;
        font-size: 13px;
        margin-left: 20px;
    }
}
</style>
