import axios from 'axios'
import Cookie from "js-cookie"

const http = axios.create({
    baseURL: window.g.API_URL,
    timeout: 10000
})

// 添加请求拦截器
http.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    const token = Cookie.get('token')

    // 如果存在 token，则添加到请求头中
    if (token) {
        config.headers.Authorization = token
    }

    return config
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response
}, function (error) {
    // 对响应错误做点什么
    return error.response
})

export default http
