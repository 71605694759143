<template>
    <div class="photos">
        <h3>{{ title }}</h3>
        <el-tabs type="border-card" v-model="active" @tab-click="handleClick">
            <el-tab-pane label="拍摄信息" name="basic">
                <el-form ref="form" :model="form" :rules="rules" style="width: 600px;">
                    <el-form-item label="封面" class="avatar">
                        <img class="user" v-if="form.cover_url" :src="form.cover_url" alt="">
                        <el-upload
                            v-if="!disabled"
                            class="upload-demo"
                            :action="`${apiUrl}/upload`"
                            name="file"
                            :headers="headers"
                            :show-file-list="false"
                            :on-error="handleCover"
                            :on-success="handleCover">
                            <el-button size="small">上传封面</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1M</div>
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="标题" prop="title">
                        <el-input id="title" v-model="form.title" :disabled="disabled" placeholder="请输入标题"></el-input>
                    </el-form-item>

                    <el-form-item label="拍摄时间" prop="date">
                        <el-date-picker
                            id="date"
                            :disabled="disabled"
                            v-model="form.date"
                            type="datetime"
                            style="width: 200px;"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间"
                            default-time="12:00:00">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="拍摄地点" prop="address">
                        <el-input id="address" v-model="form.address" :disabled="disabled" placeholder="请输入地点"></el-input>
                    </el-form-item>

                    <el-form-item label="分享给谁可见" prop="uids">
                        <el-select id="uids" v-model="form.uids" style="width: 600px;" multiple filterable :disabled="disabled" placeholder="请选择">
                            <el-option v-for="(val, key) in params.users" :key="key" :label="val" :value="parseInt(key)"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="状态" prop="status">
                        <el-select id="status" v-model="form.status" style="width: 200px;" :disabled="disabled" placeholder="请选择">
                            <el-option v-for="(val, key) in statusMap" :key="key" :label="val" :value="parseInt(key)"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="简介" prop="description">
                        <el-input type="textarea" id="description" :disabled="disabled" :rows="2" v-model="form.description" placeholder="请输入简介"></el-input>
                    </el-form-item>

                    <el-form-item v-if="!disabled">
                        <el-button type="primary" size="small" @click="doAdd">保存</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>

            <el-tab-pane v-if="edit" class="pics" name="pics" label="拍摄原图">
                <div v-if="form.can_view" class="pics-form">
                    <el-form :model="searchForm" :inline="true" label-width="90px">
                        <el-form-item>
                            <el-radio-group v-model="searchForm.status" size="mini" @input="getList">
                                <el-radio-button :label="0">全部</el-radio-button>
                                <el-radio-button :label="1">已选择</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="pics-img">
                    <el-card v-for="(item, key) in photos.slice((pageData.page-1) * pageData.limit, pageData.page * pageData.limit)" :key="key" :body-style="{ padding: '0px' }" shadow="never">
                        <i v-if="form.can_update" class="el-icon-error" @click="deletePic(item, key)"></i>
                        <el-image
                            fit="cover"
                            :src="item.path_url"
                            :preview-src-list="images"
                            class="image">
                        </el-image>

                        <el-checkbox v-if="form.can_view" class="el-icon-checkbox" v-model="item.retouch" @change="doRetouch(item)">需精修</el-checkbox>
                    </el-card>
                    <el-card v-if="form.can_upload" shadow="never">
                        <el-upload
                            :action="`${apiUrl}/plans/${form.id}/photos`"
                            name="file"
                            :headers="headers"
                            :show-file-list="false"
                            :on-error="handlePics"
                            :on-success="handlePics"
                            multiple
                            :limit="200"
                            list-type="picture-card">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-card>
                </div>

                <div class="pager">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change = "pageChanged"
                        :page-size="pageData.limit"
                        :current-page="pageData.page"
                        :total="total">
                    </el-pagination>
                </div>
            </el-tab-pane>

            <el-tab-pane v-if="edit" class="retouch" name="retouch" label="精修图">
                <el-table
                    stripe
                    :data="retouches"
                    style="width: 100%">
                    <el-table-column label="需要精修的原图" width="200">
                        <template slot-scope="scope">
                            <el-image
                                fit="cover"
                                :src="scope.row.path_url"
                                :preview-src-list="scope.row.diff_img"
                                class="image">
                            </el-image>
                        </template>
                    </el-table-column>

                    <el-table-column label="精修图" width="220">
                        <template slot-scope="scope">
                            <el-image
                                fit="cover"
                                v-if="scope.row.retouch_path_url"
                                :src="scope.row.retouch_path_url"
                                :preview-src-list="scope.row.diff_img"
                                class="image">
                            </el-image>
                            <div v-else>
                                <el-upload v-if="form.can_upload" :action="`${apiUrl}/plans/${form.id}/photos/${scope.row.id}/retouch`"
                                    name="file"
                                    :headers="headers"
                                    :show-file-list="false"
                                    :on-error="handleRetouch"
                                    :on-success="handleRetouch"
                                    list-type="picture-card">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-image v-else class="image">
                                    <div slot="error" class="image-slot">
                                        待上传...
                                    </div>
                                </el-image>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="description" label="精修评论">
                        <template slot-scope="scope">
                            <div class="comment">{{ scope.row.description }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button v-if="form.can_view" type="primary" @click="comment(scope.row)" size="mini">评论</el-button>
                            <el-button v-if="form.can_update" type="danger" @click="handleDelete(scope.row)" size="mini">移除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>

        <el-dialog
            title="精修评论"
            :visible.sync="dialogVisible"
            @close="onClosed()"
            width="800">
            <el-form ref="commentForm" :model="commentForm" :rules="commentRules" :inline="true"  label-width="100px">
                <el-form-item label="内容" prop="description">
                    <el-input type="textarea" size="small" style="width: 510px" :rows="3" v-model="commentForm.description" placeholder="请输入对该精修的评论"></el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="handleComment">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

import { getInit, addPlan, editPlan, getPlanDetail, deletePhoto, commentPhoto, deleteRetouch, togglePhoto } from "@/api/plan"
import Cookie from "js-cookie"

export default {
    data() {
        return {
            active: 'basic',
            dialogVisible: false,
            form: {
                id: '',
                cover: '',
                cover_url: '',
                title: '',
                date: '',
                address: '',
                status: 1,
                uids: [],
                description: '',
                can_update: false
            },
            commentForm: {
                id: '',
                plan_id: '',
                description: ''
            },
            statusMap: {
                "1": "未开始",
                "2": "拍摄中",
                "3": "待返片",
                "4": "精修中",
                "5": "已完成",
            },
            params: {
                users: {},
                canQuery: false,
                canAdd: false
            },
            searchForm: {
                status: 0
            },
            type: "",
            photos: [],
            total: 0,
            pageData: {
                page: 1,
                limit: 35
            },
            rules: {
                title: [
                    { required: true, message: '请输入标题', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '请输入地点', trigger: 'blur' }
                ],
                date: [
                    { required: true, message: '请选择时间', trigger: 'blur' }
                ]
            },
            commentRules: {
                description: [
                    { required: true, message: '请输入评论内容', trigger: 'blur' }
                ]
            },
            headers: {
                Authorization: Cookie.get('token'),
                Accept: 'application/json'
            },
            apiUrl: window.g.API_URL
        }
    },
    computed: {
        edit() {
            return this.type === 'edit'
        },
        disabled() {
            return this.edit && !this.form.can_update
        },
        retouches() {
            return this.photos.filter((item) => item.retouch)
        },
        images() {
            return this.photos.map((item) => item.path_url)
        },
        title() {
            return this.edit ? (this.form.can_update ? '编辑拍摄' : '拍摄详情') : '新建拍摄'
        }
    },
    created() {
        this.form.id = this.$route.query.id
        if (this.form.id) {
            this.getDetail()
            this.type = 'edit'
        } else {
            this.type = 'add'
        }
        this.getInit()
    },
    methods: {
        getInit() {
            getInit().then(({ data }) => {
                this.params = data.data
            })
        },
        getList() {
            this.pageData.page = 1

            if (this.searchForm.status === 0) {
                this.getDetail()
            } else {
                this.photos = this.photos.filter((item) => item.retouch)
                this.total = this.photos.length
            }
        },
        getDetail() {
            getPlanDetail(this.form.id).then(({ data }) => {
                console.log(data)
                if (data.code === 200) {
                    this.form = data.data
                    this.photos = data.data.photos
                    this.total = this.photos.length
                    // console.log(this.form)
                    // console.log(this.images)
                } else {
                    this.$message.error(data.data)
                }
            })
        },
        doAdd() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.edit) {
                        editPlan(this.form.id, this.form).then(({ data }) => {
                            if (data.code === 200) {
                                this.$message.success('编辑拍摄成功')
                                this.getDetail()
                            } else {
                                this.$message.error(data.data)
                            }
                        })
                    } else {
                        addPlan(this.form).then(({ data }) => {
                            if (data.code === 200) {
                                this.form = data.data
                                this.$message.success('拍摄创建成功')
                                this.$router.push({name: 'photos'})
                            } else {
                                this.$message.error(data.data)
                            }
                        })
                    }
                }
            })
        },
        handleCover(data) {
            if (data.code === 200) {
                this.form.cover_url = data.data.url
                this.form.cover = data.data.path
            } else {
                this.$message.error(JSON.parse(data.message).data)
            }
        },
        handlePics(data) {
            if (data.code === 200) {
                this.photos.push(data.data)
                this.total = this.photos.length
            } else {
                this.$message.error(JSON.parse(data.message).data)
            }
        },
        handleRetouch(data) {
            if (data.code === 200) {
                this.getDetail()
            } else {
                this.$message.error(JSON.parse(data.message).data)
            }
        },
        handleClick(tab) {
            this.active = tab.name
        },
        deletePic(row, key) {
            deletePhoto(row.plan_id, row.id).then(({ data }) => {
                if (data.code !== 200) {
                    this.$message.error(data.data)
                }
                this.photos.splice(key, 1)
            })
        },
        doRetouch(row) {
            togglePhoto(row.plan_id, row.id).then(({ data }) => {
                if (data.code === 200) {
                    if (row.retouch) {
                        this.$message.success('加入精修成功')
                    } else {
                        this.$message.success('取消精修成功')
                    }
                } else {
                    this.$message.error(data.data)
                }
                this.getDetail()
            })
        },
        comment(row) {
            this.commentForm = row
            this.dialogVisible = true
        },
        handleComment() {
            commentPhoto(this.commentForm.plan_id, this.commentForm.id, this.commentForm).then(({ data }) => {
                if (data.code === 200) {
                    this.$message.success('评论成功')
                    this.dialogVisible = false
                    this.getDetail()
                } else {
                    this.$message.error(data.data)
                }
            })
        },
        handleDelete(row) {
            this.$confirm('确认要移除该精修吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteRetouch(row.plan_id, row.id).then(({ data }) => {
                    // console.log(data)
                    if (data.code === 200) {
                        this.$message.success('删除精修成功')
                    } else {
                        this.$message.error(data.data)
                    }
                    this.getDetail()
                })
            }).catch(() => {

            })
        },
        // 对话框关闭的回调
        onClosed() {
            this.$refs.commentForm.resetFields()
        },
        pageChanged(val) {
            this.pageData.page = val
        }
    }
}
</script>

<style scoped lang="less">
    .photos {
        position: relative;

        h3 {
            font-size: 16px;
            font-weight: normal;
            padding: 10px 0;
            color: #333;
        }

        /deep/.el-tabs {
            box-shadow: none;
            min-height: 600px;
            border-radius: 2px;

            .el-tabs__header {
                background-color: #ffffff;

                .el-tabs__item {
                    font-weight: normal;
                }
            }
        }

        .el-form-item.avatar {
            /deep/.el-form-item__content {
                display: flex;
                align-items: center;
                justify-content: left;
                margin-top: 5px;

                .upload-demo {
                    //margin-left: 20px;
                    .el-upload__tip {
                        margin-top: 0;
                        line-height: 28px;
                    }

                    .el-button {
                        font-weight: normal;
                    }
                }
            }
        }

        .el-form-item {
            .user {
                width: 70px;
                height: 70px;
                border-radius: 5px;
                margin-right: 20px;
            }

            /deep/.el-form-item__label {
                float: none;
                padding-left: 2px;
            }
        }

        .pics {
            .pics-form {
                margin-left: 11px;
            }

            .pics-img {
                display: flex;
                justify-content: left;
                flex-wrap: wrap;

                /deep/.el-card {
                    &:last-child {

                        .el-card__body {
                            padding: 0 !important;
                            .el-upload--picture-card {
                                width: 150px;
                                height: 150px;
                                line-height: 150px;
                            }
                        }
                    }
                    width: 150px;
                    height: 150px;
                    margin-bottom: 40px;
                    margin-left: 9px;
                    position: relative;
                    overflow: visible;

                    .el-icon-error {
                        position: absolute;
                        z-index: 1;
                        right: 2px;
                        top: 0;
                        font-size: 25px;
                        font-weight: normal;
                        color: #ffffff;
                        cursor: pointer;
                    }

                    .el-icon-checkbox {
                        position: absolute;
                        z-index: 1;
                        left: 45px;
                        bottom: -25px;
                        color: #333333;
                        font-weight: normal;
                    }

                    .image {
                        width: 150px;
                        height: 150px;
                    }

                }
            }
            .pager {
                position: absolute;
                bottom: 10px;
                right: 20px;
                margin-top: 20px;
            }
        }

        .retouch {
            height: calc(100% - 62px);
            position: relative;

            /deep/.el-table {
                .image {
                    width: 100px;
                    height: 100px;
                    border-radius: 2px;

                    div.image-slot {
                        width: 100%;
                        height: 100%;
                        line-height: 100px;
                        border: 1px dashed #ccc;
                        text-align: center;
                        color: #999999;
                        background: #ffffff;
                        font-size: 12px;
                    }
                }
                .el-upload--picture-card {
                    width: 100px;
                    height: 100px;
                    line-height: 100px;
                    background: #ffffff;
                }
                .comment {
                    border: 1px solid #efefef;
                    background: #ffffff;
                    height: 50px;
                    line-height: 50px;
                    border-radius: 5px;
                    padding: 0 5px;
                    font-size: 12px;
                    color: #333333;
                }
            }

            /deep/.el-table__header-wrapper {
                .cell {
                    font-weight: normal;
                    color: #666666;
                }
            }
        }
    }
</style>